import "../sass/app.sass"

import anime from "animejs"
import Glider from "glider-js"

// Collect and load needed functions
function init() {
  const loader = document.querySelectorAll("[data-init]")

  if (loader.length >= 1) {
    for (const key in loader) {
      let func = ''

      if (loader[key].dataset) {
        func = loader[key].dataset.init
      }

      if (func === 'blockSlider') {
        blockSlider()
      } else if (func === 'claims') {
        claims()
      }
    }
  }
}

const light_dark_selector = document.querySelectorAll('.light-dark')
light_dark_selector.forEach((item) => {
  const buttonDark = item.querySelector('.js-toggle-dark-mode')
  const buttonLight = item.querySelector('.js-toggle-light-mode')

  buttonDark.addEventListener('click', (event) => {
    event.preventDefault()
    document.body.classList.add('is-darkmode')
    localStorage.appearance = 'dark'
  })

  buttonLight.addEventListener('click', (event) => {
    event.preventDefault()
    document.body.classList.remove('is-darkmode')
    localStorage.appearance = 'light'
  })

  if (localStorage.appearance === 'dark') {
    document.body.classList.add('is-darkmode')
  } else {
    document.body.classList.remove('is-darkmode')
  }
})

// Handle Claim
// needs animejs
const claims = () => {
  let index = 0
  let visibleClaim = document.querySelector(".visible-claim")

  const shellClaims = document.querySelectorAll(".shell-claim")
  const toLetters = s => {
    return s.replace(/\S/g, "<span class='letter'>$&</span>")
  }

  visibleClaim.innerHTML = toLetters(shellClaims[0].textContent)

  anime({
    targets: document.querySelectorAll('.visible-claim .letter'),
    loop: true,
    delay: 2000,
    duration: 3000,
    width: '100%',
    loopBegin: function () {
      index = (index + 1) % shellClaims.length
      const next = shellClaims[index]
      document.querySelector('.visible-claim').innerHTML = toLetters(next.textContent)
      anime({
        targets: '.visible-claim .letter',
        width: [0, 1],
        opacity: [0, 1],
        translateZ: 0,
        easing: "easeOutExpo",
        duration: 1200,
        delay: (el, i) => 40 * i + Math.random() * 20,
      })
    },
    loopComplete: function () {
      visibleClaim.innerHTML = ''
    }
  })
}

// Block: slider
const blockSlider = () => {

  window.addEventListener('load', function () {
    [].forEach.call(document.querySelectorAll('.glider'), function (ele) {
      new Glider(ele, {
        arrows: {
          prev: ele.parentNode.querySelector('.block-slider .previous'),
          next: ele.parentNode.querySelector('.block-slider .next')
        }
      });
    });
  });
}



// Block: testimonials
const testimonials = document.querySelectorAll('.block-testimonials')
testimonials.forEach((item) => {
  const slides = item.querySelector('.block-slider_slides')
  const previous = item.querySelector('.previous-slide')
  const next = item.querySelector('.next-slide')
  const glider = new Glider(slides)

  glider.setOption({
    arrows: {
      next: next,
      prev: previous,
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
    ],
    slidesToShow: 1,
    slidesToScroll: 1,
    scrollLock: true,
  })

  glider.refresh(true)
})

const cookie_modal = document.querySelector('.cookie-modal')
if (cookie_modal) {
  const checkboxParent = cookie_modal.querySelectorAll('.cookie-modal__option')

  checkboxParent.forEach((item) => {
    const checkboxElement = item.querySelector('.cookie-modal__checkbox')

    item.addEventListener('click', () => {
      if (checkboxElement.checked) {
        item.classList.add('checked')
      } else {
        item.classList.remove('checked')
      }
    })
  })
}

// News load more
const articles = document.querySelector('.js-articles');
if (articles) {
  const articles_more_selector = articles.querySelector('.js-more-articles');
  const articles_row_selector = articles.querySelector('.article-list')
  let articles_page = parseInt(articles.dataset.page);

  if (articles_more_selector) {
    const fetchArticles = async () => {
      let url = `${window.location.href.split('#')[0]}.json/page:${articles_page}`;

      try {
        const response = await fetch(url);
        const {html, more} = await response.json();

        if (!more) {
          articles_more_selector.remove()
        }

        articles_row_selector.insertAdjacentHTML('beforeend', html);
        articles_page++;
      } catch (error) {
        console.log('Fetch error: ', error);
      }
    }

    articles_more_selector.addEventListener('click', fetchArticles);
  }
}

const cookie_settings = document.querySelectorAll('.edit-cookie')
cookie_settings.forEach((item) => {
  item.addEventListener('click', function() {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('cookies:update', true, false)
    document.querySelector('body').dispatchEvent(event)
  });

  // react to cookie changes
  const body = document.querySelector('body');
  body.addEventListener('cookies:saved', function(event) {
    window.location.reload()
  })

})

init()
